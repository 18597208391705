import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import isMobile from "@src/utils/isMobile";
import { incrementMetric } from "@src/utils/logClient";

const Home = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { appDownload } = parsedLocation;
  const isMobileDevice = isMobile();

  useRedirectHandler(() => {
    if (appDownload) {
      if (isMobileDevice.android.device) {
        return "https://play.google.com/store/apps/details?id=com.classdojo.android";
      }
      if (isMobileDevice.apple.device) {
        return "https://apps.apple.com/app/classdojo/id552602056";
      }
    }

    incrementMetric("external_site.universal_link.fallback", { tag: "Home" });
    return "https://www.classdojo.com";
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Home;
